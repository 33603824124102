import styles from './Chip.module.scss';

import Button from '@shared/components/atoms/Button';
import cn from 'classnames';

interface ChipProps {
  children: React.ReactNode;
  className?: string;
  href?: string;
}

const Chip = (props: ChipProps) => {
  const { children, className, href } = props;

  return href ? (
    <Button className={cn(className, styles.root)} href={href}>
      {children}
    </Button>
  ) : (
    <span className={cn(className, styles.root)}>{children}</span>
  );
};

export default Chip;
